import L from 'leaflet';
import {
    PruneCluster,
    PruneClusterForLeaflet,
} from 'exports-loader?exports[]=PruneCluster&exports[]=PruneClusterForLeaflet!prunecluster/dist/PruneCluster.js';

/* custom marker icon */
const createCustomMarkerIcon = () => {
    return L.divIcon({className: 'rioglyph rioglyph-sphere text-color-rating-5'});
};

/* custom cluster icon */
const createClusterCustomIcon = (population) => {
    return L.divIcon({
        html: `<span>${population}</span>`,
        className: 'marker-cluster-custom',
        iconSize: L.point(45, 45, true),
    });
};

function fillMarkerList(positions) {
    const markerList = [];

    positions.forEach(pos => {
        const marker = new PruneCluster.Marker(pos.lat, pos.lng);
        marker.data.icon = createCustomMarkerIcon;
        markerList.push(marker);
    });

    return markerList;
}

export function clusterLayer(map, positions, isSelected) {
    const clusterView = new PruneClusterForLeaflet(250, 20);

    clusterView.BuildLeafletClusterIcon = (cluster => {
        const population = cluster.population;
        return createClusterCustomIcon(population);
    });

    const markerList = fillMarkerList(positions);
    // eslint-disable-next-line new-cap
    clusterView.RegisterMarkers(markerList);

    if (isSelected) {
        map.addLayer(clusterView);
    }

    return clusterView;
}
