/*eslint camelcase: ["error", {allow: ["^app_"]}]*/
// This is the runtime configuration being used for local development
// where authorization is usually mocked

export const sandboxConfig = {
    backend: {
        DEVICE_INFO_SERVICE: `https://api.vcp-connector.vcdm-dev.rio.cloud/device-info`,
    },
    frontend: {
        DEVICE_STATE: 'https://device-state.vcdm-dev.rio.cloud/index.html',
    },
    homeRoute: `https://heatmap.vcdm-dev.rio.cloud/index.html`,
    id: 'env.sandbox',
    login: {
        clientId: 'device-info-frontend-client',
        authority: 'https://docker-azure-mock-auth-server.vcdm-dev.rio.cloud',
        mockAuthorization: false,
        mockLocale: 'de-DE',
        preventRedirect: false,
        redirectUri: `https://heatmap.vcdm-dev.rio.cloud/redirect.html`,
        silentRedirectUri: `https://heatmap.vcdm-dev.rio.cloud/redirect.html`,
    },
    logoutUri: `https://heatmap.vcdm-dev.rio.cloud/index.html`,
};
