/*eslint camelcase: ["error", {allow: ["^app_"]}]*/
// This is the runtime configuration being used for local development
// where authorization is usually mocked

export const localConfig = {
    backend: {
        USERADMIN_SERVICE: `${window.location.origin}/usersettings`,
        USER_SETTINGS_SERVICE: `${window.location.origin}/usersettings`,
        DEVICE_INFO_SERVICE: `http://localhost:8081/device-info`,
    },
    frontend: {
        DEVICE_STATE: 'https://device-state.vcp-connector.rio.cloud/index.html',
    },
    homeRoute: `${window.location.origin}`,
    id: 'env.local',
    login: {
        clientId: '69389347-b6ec-4b5b-b73d-9dfb26190e0c',
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: true,
        mockLocale: 'de-DE',
        preventRedirect: true,
        redirectUri: `${window.location.origin}/index.html`,
        silentRedirectUri: `${window.location.origin}/index.html`,
    },
    logoutUri: `${window.location.origin}`,
};
