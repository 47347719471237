import get from 'lodash/fp/get';
import merge from 'lodash/fp/merge';

import { localConfig } from './env/env.local';
import { productionConfig } from './env/env.production';
import { testConfig } from './env/env.test';
import { sandboxConfig } from './env/env.sandbox';

export const getConfig = get('config');

export const getLoginClientId = get('config.login.clientId');

export const getLoginRedirectUri = get('config.login.redirectUri');

export const getUserSettingsBackend = get('config.backend.USER_SETTINGS_SERVICE');

export const determineFeatureToggles = (window) => {

    const param = (regex, defaultValue = null) => {
        let result = defaultValue;
        decodeURI(window.location.href).replace(regex, (_, it) => {
            result = it;
        });
        return result;
    };

    const detectToggle = (transform, name, defaultValue = null) => {
        // Note that IE11 throws a SyntaxError when using 'u' here
        // eslint-disable-next-line require-unicode-regexp
        let value = param(new RegExp(`${name}=([^&]+)`));

        if (value) {
            try {
                window.localStorage.setItem(
                    name,
                    value
                );
            } catch (_) {
                // Intentionally left blank
            }
        } else {
            try {
                value =
                    window.localStorage.getItem(name);
            } catch (_) {
                // Intentionally left blank
            }
        }

        return value ? transform(value) : defaultValue;
    };

    // ... add your custom feature toggles here
    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        mockedToken: detectToggle(String, 'ft_mockToken'),
        tracing: detectToggle(Boolean, 'ft_tracing'),
    };
};

export const getRuntimeConfig = (window, env = {}) => {

    const defaultConfig = {
        backend: {},
        homeRoute: `${window.location.origin}`,
        id: 'env.stub',
        login: {
            // TODO: Request and supply your App's `client_id` as
            //       well as the needed OAuth scopes here
            clientId: '<you-need-to-obtain-your-own-client-id>',
            oauthScope: [
                'openid',
                'profile',
                'email',
                'user-managment.read',
                'fleetadmin.read',
            ],
            mockLocale: 'en-GB',
            preventRedirect: false,
        },
    };

    let config = localConfig;

    if (env.isRunningOnProd) {
        config = productionConfig;
    }

    if (env.featureToggles.enforcedEnv === 'production') {
        config = productionConfig;
    }

    if (env.isTestEnv) {
        config = testConfig;
    }

    if (env.runsInSandbox) {
        config = sandboxConfig;
    }

    return merge(merge({}, defaultConfig), config);
};

export const configureEnv = (window, processEnv) => {
    const { location: { host } } = window;
    const { NODE_ENV } = processEnv;

    const isProdEnv = host === 'heatmap.vcp-connector.rio.cloud';
    const isStagingEnv = host === 'test.rio.cloud';
    const isTestEnv = NODE_ENV === 'test';
    const isLocalEnv = !isProdEnv && !isStagingEnv && !isTestEnv;

    const shouldSendMetrics = isProdEnv || isStagingEnv;

    const isProdBuild = NODE_ENV === 'production';
    const isRunningOnProd = host === 'heatmap.vcp-connector.rio.cloud';
    const isRunningOnStaging = host === 'test.rio.cloud';
    const runsInSandbox = isProdBuild && !isRunningOnStaging && !isRunningOnProd;

    // "Real" old IE - as in: not Edge in IE11 mode - is quite iffy about
    // accessing the console when the developer tools are not visible
    // so we will be extra defensive here, just to be safe
    const console = typeof window['console'] !== 'undefined' ?
        window.console :
        null;

    // IE... I'm looking at you <o.O>
    const hasConsole = !!console;

    const shouldRestrictLog = NODE_ENV === 'production';
    const shouldUseConsoleLogger = false; //NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = determineFeatureToggles(window);

    const env = {
        featureToggles,
        hasConsole,
        isLocalEnv,
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        isTestEnv,
        runsInSandbox,
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
        runtimeConfig: {},
    };

    env.runtimeConfig = getRuntimeConfig(window, env);

    return env;
};

export const env = configureEnv(window, process.env);

if (!env.hasConsole && window) {
    // I'm still looking at you IE <o.O>
    const noop = () => {};

    const fakeConsole = {
        debug: noop,
        info: noop,
        isFakeConsole: true,
        log: noop,
        warn: noop,
    };

    window.console = fakeConsole;
}

if (env.featureToggles.tracing) {
    // eslint-disable-next-line no-console
    console.log(env);
}
