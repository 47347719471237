import _ from 'lodash';
import {
    HIDE_SESSION_EXPIRED_DIALOG,
    DEVICES_FETCH_SUCCESSFUL,
    SET_IS_SEARCHING,
    SAVE_MAP_FILTER_SETTINGS,
    SET_SHOW_MAP_FILTER_SETTINGS,
} from './actions';

import {mapPositions} from '../mappers/Mapper';
import {filterDevices} from './filter';

const initialState = {
    sessionExpiredAcknowledged: false,
    devices: [],
    isSearching: false,
    filterItems: [],
    showSettings: false,
    filteredPositions: [],
};

function handleSaveMapFilterSettings(state, action) {
    const changeSet = action.payload.changeSet;
    const filterItems = state.filterItems.map(a => Object.assign({}, a));

    if (changeSet.length === 0) {
        return state;
    }

    changeSet.map(entry => {
        const filterItemFound = filterItems.find(item => {
            return item.dataField === entry.dataField;
        });

        if (filterItemFound !== undefined && filterItemFound.checked !== entry.checked) {
            // eslint-disable-next-line no-return-assign
            return filterItemFound.checked = entry.checked;
        }
        if (filterItemFound === undefined) {
            filterItems.push(entry);
        }
        return false;
    });

    if (_.isEqual(filterItems, state.filterItems)) {
        return state;
    }

    const filteredPositions = mapPositions(filterDevices(filterItems, state.devices));

    return {
        ...state,
        filterItems,
        filteredPositions,
    };
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case HIDE_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionExpiredAcknowledged: true,
            };
        case DEVICES_FETCH_SUCCESSFUL:
            return {
                ...state,
                devices: action.payload,
            };
        case SET_IS_SEARCHING:
            return {
                ...state,
                isSearching: action.payload.isSearching,
            };
        case SET_SHOW_MAP_FILTER_SETTINGS:
            return {
                ...state,
                showSettings: action.payload.showed,
            };
        case SAVE_MAP_FILTER_SETTINGS:
            return handleSaveMapFilterSettings(state, action);
        default:
            return state;
    }
};

export default reducer;
