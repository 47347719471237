import React, {PureComponent} from 'react';
import {
    Checkbox,
    ConfirmationDialog,
    DataTabs,
    DataTab,
} from 'rio-uikit';
import {FormattedMessage} from 'react-intl';
import {getFilterItems, getShowSettings} from './selectors';
import {connect} from 'react-redux';
import {handleSaveMapFilterSettings, handleSetShowFilterSettings} from './actions';
import {
    KEY_VEHICLE_STATUS_PREFIX,
    KEY_HWVARIANT_PREFIX,
    KEY_REGION_PREFIX,
    KEY_BRAND_PREFIX,
    KEY_VVARIANT_PREFIX,
} from '../mappers/Mapper';
import './styles.less';

export const PANEL_REGIONS_ID = 'panelregions';
export const PANEL_HWVARIANTS_ID = 'panelhwvariants';
export const PANEL_ACTIVATED_VEHICLE_ID = 'panelactvehicle';
export const PANEL_BRANDS_ID = 'panelbrands';
export const PANEL_VVARIANT_ID = 'panelvvariants';
export const ABORT_BTN_ID = 'service.heatmap.filter.settings.abort';
export const SAVE_BTN_ID = 'service.heatmap.filter.settings.save';
export const TABLE_SETTINGS_TITLE_ID = 'service.heatmap.filter.settings.title';
export const TAB_KEY_GENERAL = 'tab_general';
export const TAB_KEY_DEVICES = 'tab_devices';
export const TAB_KEY_VEHICLES = 'tab_vehicles';

const REGION_LBL_ID = 'service.heatmap.filter.settings.region';
const HWVARIANTS_LBL_ID = 'service.heatmap.filter.settings.hwvariants';
const ACTIVATED_VEHICLE_LBL_ID = 'service.heatmap.filter.settings.vehicle.status';
const BRANDS_LBL_ID = 'service.heatmap.filter.settings.brands';
const VVARIANT_LBL_ID = 'service.heatmap.filter.settings.vvariants';
const TAB_TITLE_GENERAL = 'service.heatmap.filter.settings.tab.title.general';
const TAB_TITLE_DEVICES = 'service.heatmap.filter.settings.tab.title.devices';
const TAB_TITLE_VEHICLES = 'service.heatmap.filter.settings.tab.title.vehicles';

export class MapFilterSettings extends PureComponent {
    constructor(props) {
        super(props);

        this.setRegionalChangeInState = this.setRegionalChangeInState.bind(this);
        this.setHWVariantChangeInState = this.setHWVariantChangeInState.bind(this);
        this.setActivatedVehicleChangeInState = this.setActivatedVehicleChangeInState.bind(this);
        this.setBrandChangeInState = this.setBrandChangeInState.bind(this);
        this.setVvariantChangeInState = this.setVvariantChangeInState.bind(this);

        this.state = {
            changeSet: [],
            regionalOptions: [],
            hardwareVariantOptions: [],
            activatedVehicleOptions: [],
            brandOptions: [],
            vvariantOptions: [],
            tabKey: TAB_KEY_GENERAL,
        };
    }

    getFormattedMessage(id) {
        return <FormattedMessage id={id}/>;
    }

    isRegionDataField(dataField) {
        return dataField !== undefined && dataField.startsWith(KEY_REGION_PREFIX);
    }

    isHardwareVariantDataField(dataField) {
        return dataField !== undefined && dataField.startsWith(KEY_HWVARIANT_PREFIX);
    }

    isActivatedVehicleDataField(dataField) {
        return dataField !== undefined && dataField.startsWith(KEY_VEHICLE_STATUS_PREFIX);
    }

    isBrandDataField(dataField) {
        return dataField !== undefined && dataField.startsWith(KEY_BRAND_PREFIX);
    }

    isVvariantDataField(dataField) {
        return dataField !== undefined && dataField.startsWith(KEY_VVARIANT_PREFIX);
    }

    setFilterOptionsInState(filterItems,
        regionalOptions = [],
        hardwareVariantOptions = [],
        activatedVehicleOptions = [],
        brandOptions = [],
        vvariantOptions = []) {

        filterItems.map(it => {
            const newOption = {
                dataField: it.dataField,
                checked: it.checked,
                text: it.text,
            };
            if (this.isRegionDataField(newOption.dataField)) {
                regionalOptions.push(newOption);
            }
            if (this.isHardwareVariantDataField(newOption.dataField)) {
                hardwareVariantOptions.push(newOption);
            }
            if (this.isActivatedVehicleDataField(newOption.dataField)) {
                activatedVehicleOptions.push(newOption);
            }
            if (this.isBrandDataField(newOption.dataField)) {
                brandOptions.push(newOption);
            }
            if (this.isVvariantDataField(newOption.dataField)) {
                vvariantOptions.push(newOption);
            }
            return it;
        });

        this.setState(() => ({
            regionalOptions: regionalOptions,
            hardwareVariantOptions: hardwareVariantOptions,
            activatedVehicleOptions: activatedVehicleOptions,
            brandOptions: brandOptions,
            vvariantOptions: vvariantOptions,
        }));
    }

    componentDidUpdate() {
        const {
            regionalOptions,
            hardwareVariantOptions,
            activatedVehicleOptions,
            brandOptions,
            vvariantOptions,
        } = this.state;
        const {showSettings, filterItems} = this.props;

        if (showSettings &&
            !regionalOptions.length &&
            !hardwareVariantOptions.length &&
            !activatedVehicleOptions.length &&
            !brandOptions.length &&
            !vvariantOptions.length &&
            filterItems.length) {
            this.setFilterOptionsInState(filterItems);
        }
    }

    determineAllSelectedStatus(options) {
        let selected = 0;

        options.forEach(o => {
            if (o.checked) {
                selected++;
            }
        });

        //0 = not all selected, 1 = partially, 2 = all selected
        if (selected === 0) {
            return 0;
        } else if (selected < options.length) {
            return 1;
        }
        return 2;
    }

    setRegionalChangeInState(changeSet, options) {
        this.setState(() => ({
            ...this.state,
            changeSet: changeSet,
            regionalOptions: options,
        }));
    }

    setHWVariantChangeInState(changeSet, options) {
        this.setState(() => ({
            ...this.state,
            changeSet: changeSet,
            hardwareVariantOptions: options,
        }));
    }

    setActivatedVehicleChangeInState(changeSet, options) {
        this.setState(() => ({
            ...this.state,
            changeSet: changeSet,
            activatedVehicleOptions: options,
        }));
    }

    setBrandChangeInState(changeSet, options) {
        this.setState(() => ({
            ...this.state,
            changeSet: changeSet,
            brandOptions: options,
        }));
    }

    setVvariantChangeInState(changeSet, options) {
        this.setState(() => ({
            ...this.state,
            changeSet: changeSet,
            vvariantOptions: options,
        }));
    }

    handleSelectAll(options, changeSet, setFunction) {
        const allSelectedStatus = this.determineAllSelectedStatus(options);

        //if all options are unchecked or only partially, we check them all
        //otherwise we uncheck them all
        const checked = allSelectedStatus === 0 || allSelectedStatus === 1;

        const newOptions = options.map(o =>
            ({...o, checked: checked})
        );

        let newChangeSet = [];
        if (changeSet.length === 0) {
            newChangeSet = [...newOptions];
        } else {
            newChangeSet = [...changeSet];
            newOptions.forEach(o => {
                const foundChangeSet = newChangeSet.find(set => (set.dataField === o.dataField));
                if (foundChangeSet !== undefined) {
                    foundChangeSet.checked = checked;
                } else {
                    newChangeSet.push(o);
                }
            });
        }

        if (setFunction) {
            setFunction(newChangeSet, newOptions);
        }
    }

    handleOptionClicked(options, selectedOption, changeSet, setFunction) {
        const newOptions = options.map(o =>
            o === selectedOption ? {...o, checked: !selectedOption.checked} : o
        );

        const newChangeSet = [...changeSet];
        if (newChangeSet.length === 0) {
            newChangeSet.push({...selectedOption, checked: !selectedOption.checked});
        } else {
            const foundChangeSet = newChangeSet.find(set => (set.dataField === selectedOption.dataField));
            if (foundChangeSet !== undefined) {
                foundChangeSet.checked = !selectedOption.checked;
            } else {
                newChangeSet.push({...selectedOption, checked: !selectedOption.checked});
            }
        }

        if (setFunction) {
            setFunction(newChangeSet, newOptions);
        }
    }

    renderSectionPanel(options, id, heading, changeSet, setFunction) {
        if (options && options.length > 0) {
            const allSelectedStatus = this.determineAllSelectedStatus(options);

            return <div id={id}
                className='panel panel-default border-left-0 border-right-0 border-top-0 border-bottom-0
                margin-bottom-5'>
                <div className='panel-heading' style={{display: 'flex'}}>
                    <Checkbox key={0} onClick={() => this.handleSelectAll(options, changeSet, setFunction)}
                        indeterminate={allSelectedStatus === 1} checked={allSelectedStatus === 2}/>
                    <div className='panel-title text-size-normal'>{heading}</div>
                </div>
                <div className='panel-body'>
                    {options.map((option, i) => {
                        return <Checkbox key={option.dataField.concat('_id', i)}
                            onClick={() => this.handleOptionClicked(options, option, changeSet, setFunction)}
                            checked={option.checked}>
                            {option.text}
                        </Checkbox>;
                    })}
                </div>
            </div>;
        }
    }

    renderOptions() {
        const {
            changeSet,
            regionalOptions,
            hardwareVariantOptions,
            activatedVehicleOptions,
            brandOptions,
            vvariantOptions,
        } = this.state;

        return (
            <div id='mapFilterOptions'>
                <DataTabs activeKey={this.state.tabKey} onSelectTab={tabKey => this.setState({ tabKey })}
                    bordered={false}>
                    <DataTab tabKey={TAB_KEY_GENERAL} title={this.getFormattedMessage(TAB_TITLE_GENERAL)}>
                        { this.renderSectionPanel(
                            activatedVehicleOptions,
                            PANEL_ACTIVATED_VEHICLE_ID,
                            this.getFormattedMessage(ACTIVATED_VEHICLE_LBL_ID),
                            changeSet,
                            this.setActivatedVehicleChangeInState) }
                        { this.renderSectionPanel(
                            regionalOptions,
                            PANEL_REGIONS_ID,
                            this.getFormattedMessage(REGION_LBL_ID),
                            changeSet,
                            this.setRegionalChangeInState) }
                    </DataTab>
                    <DataTab tabKey={TAB_KEY_DEVICES} title={this.getFormattedMessage(TAB_TITLE_DEVICES)}>
                        { this.renderSectionPanel(
                            hardwareVariantOptions,
                            PANEL_HWVARIANTS_ID,
                            this.getFormattedMessage(HWVARIANTS_LBL_ID),
                            changeSet,
                            this.setHWVariantChangeInState) }
                    </DataTab>
                    <DataTab tabKey={TAB_KEY_VEHICLES} title={this.getFormattedMessage(TAB_TITLE_VEHICLES)}>
                        { this.renderSectionPanel(
                            brandOptions,
                            PANEL_BRANDS_ID,
                            this.getFormattedMessage(BRANDS_LBL_ID),
                            changeSet,
                            this.setBrandChangeInState) }
                        { this.renderSectionPanel(
                            vvariantOptions,
                            PANEL_VVARIANT_ID,
                            this.getFormattedMessage(VVARIANT_LBL_ID),
                            changeSet,
                            this.setVvariantChangeInState) }
                    </DataTab>
                </DataTabs>
            </div>
        );
    }

    clearState(state) {
        this.setState(() => ({
            ...state,
            changeSet: [],
            regionalOptions: [],
            hardwareVariantOptions: [],
            activatedVehicleOptions: [],
            brandOptions: [],
            vvariantOptions: [],
        }));
    }

    render() {
        const {showSettings, saveMapFilterSettings, setShowFilterSettings} = this.props;
        const {changeSet} = this.state;

        return (
            <ConfirmationDialog
                show={showSettings}
                title={this.getFormattedMessage(TABLE_SETTINGS_TITLE_ID)}
                content={this.renderOptions()}
                onClickConfirm={() => {
                    saveMapFilterSettings(changeSet);
                    setShowFilterSettings(false);
                    this.clearState(this.state);
                }}
                onClickCancel={() => {
                    setShowFilterSettings(false);
                    this.clearState(this.state);
                }}
                cancelButtonText={this.getFormattedMessage(ABORT_BTN_ID)}
                confirmButtonText={this.getFormattedMessage(SAVE_BTN_ID)}
            />
        );
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
        saveMapFilterSettings: (value) => dispatch(handleSaveMapFilterSettings(value)),
        setShowFilterSettings: (value) => dispatch(handleSetShowFilterSettings(value)),
    };
};

export const mapStateToProps = (state) => {
    return {
        filterItems: getFilterItems(state),
        showSettings: getShowSettings(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapFilterSettings);
