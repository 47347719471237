export const HIDE_SESSION_EXPIRED_DIALOG = 'app/HIDE_SESSION_EXPIRED_DIALOG';
export const hideSessionExpiredDialog = () => ({
    type: HIDE_SESSION_EXPIRED_DIALOG,
});

export const DEVICES_FETCH_SUCCESSFUL = 'app/DEVICES_FETCH_SUCCESSFUL';
export const devicesFetchSuccessful = (devices) => ({
    type: DEVICES_FETCH_SUCCESSFUL,
    payload: devices,
});

export const SET_IS_SEARCHING = 'SET_IS_SEARCHING';
export const handleIsSearching = (value) => ({
    type: SET_IS_SEARCHING,
    payload: {
        isSearching: value,
    },
});

export const SAVE_MAP_FILTER_SETTINGS = 'SAVE_MAP_FILTER_SETTINGS';
export const handleSaveMapFilterSettings = (value) => ({
    type: SAVE_MAP_FILTER_SETTINGS,
    payload: {
        changeSet: value,
    },
});

export const SET_SHOW_MAP_FILTER_SETTINGS = 'SET_SHOW_MAP_FILTER_SETTINGS';
export const handleSetShowFilterSettings = (value) => ({
    type: SET_SHOW_MAP_FILTER_SETTINGS,
    payload: {
        showed: value,
    },
});
