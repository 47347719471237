/* eslint-disable camelcase */
import { Log, InMemoryWebStorage, UserManager, WebStorageStateStore } from 'oidc-client/lib/oidc-client';
import join from 'lodash/fp/join';
import getOr from 'lodash/fp/getOr';

import { env, configureEnv } from '../../env';

import { configureStorage } from './storage';
import { mapUserProfile } from './userProfile';

const trace = env.featureToggles.tracing ?
    // eslint-disable-next-line no-console
    (...args) => console.log(`[oidcLogin]`, ...args) :
    () => {};

const getWindow = () => typeof window === 'undefined' ? {} : window;

const param = (window, regex, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (_, it) => {
        result = it;
    });
    return result;
};

const pullLocale = getOr('en-GB', 'profile.locale');

export const adaptPublishedInfo = (result = {}) => ({
    accessToken: result.access_token,
    expiresInSeconds: result.expires_in,
    idToken: result.profile,
    locale: pullLocale(result),
    profile: mapUserProfile(result.profile),
});

// eslint-disable-next-line no-shadow
export const configureAuth = (window, env) => {
    const { runtimeConfig } = configureEnv(window, env);

    const redirectUri = runtimeConfig.login.redirectUri;
    const silentRedirectUri = runtimeConfig.login.silentRedirectUri;

    const settings = {
        authority: `${runtimeConfig.login.authority}`,
        client_id: `${runtimeConfig.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: `id_token token`,
        resource: `${runtimeConfig.login.clientId}`,
        scope: join(' ', runtimeConfig.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    trace('oidc.auth.settings', settings);

    return new UserManager(settings);
};

// eslint-disable-next-line no-shadow
export const configureSetupOAuth = (auth, storage, window, env) => {

    const { featureToggles, runtimeConfig } = configureEnv(window, env);

    if (featureToggles.tracing) {
        Log.logger = console;

        Log.level = Log.DEBUG;
    }

    const isFreshRedirect = Boolean(param(window, /access_token=([^&]+)/u));

    const saveCurrentRoute = () => {
        const initialRoute =
            [
                window.location.pathname,
                window.location.hash,
                window.location.search,
            ].join('').replace(/^\/#/u, '');

        storage.saveRoute(initialRoute);

        trace('saving initial route', initialRoute);
    };

    return (config) => {

        const trySignin = () =>
            auth.signinSilent().then((result) => {
                trace('oidc.signinSilent success!', result);
                config.onTokenRenewed(adaptPublishedInfo(result));

                if (!isFreshRedirect) {
                    saveCurrentRoute();
                }

                return result;
            }).catch((error) => {
                trace('oidc.signinSilent failed', error);

                if (!isFreshRedirect) {
                    saveCurrentRoute();
                }

                config.onTokenExpired();
                return Promise.reject(error);
            });

        auth.events.addAccessTokenExpiring((...args) => {
            trace('oidc.accessTokenExpiring', ...args);
            trace('  triggering manual silent renewal...');

            saveCurrentRoute();
            return trySignin();
        });

        auth.events.addAccessTokenExpired((...args) => {
            trace('oidc.accessTokenExpired', ...args);
            config.onTokenExpired();
        });

        auth.events.addSilentRenewError((...args) => {
            trace('oidc.silentRenewError', ...args);
            config.onTokenExpired();
        });

        auth.events.addUserLoaded((...args) => {
            trace('oidc.userLoaded', ...args);
        });

        auth.events.addUserSignedOut((...args) => {
            trace('oidc.userSignedOut', ...args);
            config.onTokenExpired();
        });

        return trySignin().catch((error) => {
            trace('oidc.signinSilent failed, trying page redirect...', error);

            if (runtimeConfig.login.preventRedirect) {
                // eslint-disable-next-line no-console
                console.warn('[feature/login] redirect prevented due to config');
            } else {
                saveCurrentRoute();
                auth.signinRedirect();
            }

            return Promise.reject(new Error(`Need to sign in`));
        });
    };
};

const runtimeAuth = configureAuth(window, process.env);

if (env.featureToggles.tracing) {
    trace(`runtimeAuth`, runtimeAuth);
}

export const setupOAuth = configureSetupOAuth(
    runtimeAuth,
    configureStorage(getWindow()),
    getWindow(),
    process.env
);

export const mockOAuth = ({ onTokenRenewed }) => {
    // eslint-disable-next-line no-console
    console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);

    onTokenRenewed(adaptPublishedInfo({
        access_token: env.featureToggles.mockedToken || 'valid-mocked-oauth-bogus-token',
        // eslint-disable-next-line no-magic-numbers
        expires_in: 60 * 60 * 24 * 365,
        profile: {
            account: 'mockaccount',
            azp: 'test-client',
            email: 'test@example.com',
            family_name: 'Client',
            given_name: 'Test',
            locale: env.runtimeConfig.login.mockLocale,
            name: 'Test Client',
            sub: 'prod-rio-users:mock-user',
        },
    }));

    return Promise.resolve();
};

export const configureRetrieveInitialState = (storage) => () => ({
    initialRoute: storage.getRoute(),
});

export const retrieveInitialState =
    configureRetrieveInitialState(configureStorage(getWindow()));

