import L from 'leaflet';
import 'leaflet.heat';

export function heatLayer(map, positions, isSelected) {
    const heatLayerComponent = L.heatLayer([]);
    heatLayerComponent.setOptions({
        minOpacity: 0.3,
        radius: 10,
        blur: 4,
    });

    if (isSelected) {
        heatLayerComponent.addTo(map);
    }

    positions.forEach(pos => {
        heatLayerComponent.addLatLng(L.latLng(pos.lat, pos.lng, 0.5));
    });

    return heatLayerComponent;
}
