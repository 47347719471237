/* eslint-disable complexity */
import {
    getRegionText,
    getActVehicleKey,
    KEY_VEHICLE_STATUS_PREFIX,
    KEY_HWVARIANT_PREFIX,
    KEY_REGION_PREFIX,
    KEY_BRAND_PREFIX,
    KEY_VVARIANT_PREFIX,
} from '../mappers/Mapper';

function applyFilter(devices, filter, prefix) {
    return devices.filter(d => {
        const found = filter.find(f => {

            if (KEY_REGION_PREFIX === prefix) {
                return f.text === getRegionText(d.gw);
            }

            if (KEY_HWVARIANT_PREFIX === prefix) {
                return (f.text === d.hwv || (f.text === 'UNKNOWN' && !d.hwv));
            }

            if (KEY_VEHICLE_STATUS_PREFIX === prefix) {
                return f.dataField === getActVehicleKey(d.act);
            }

            if (KEY_BRAND_PREFIX === prefix) {
                return (f.text === d.brand || (f.text === 'UNKNOWN' && !d.brand));
            }

            if (KEY_VVARIANT_PREFIX === prefix) {
                return (f.text === d.vv || (f.text === 'UNKNOWN' && !d.vv));
            }

            return false;
        });
        return found !== undefined;
    });
}

function getCheckedFiltersByPrefix(filterItems, prefix) {
    return filterItems.filter(it => it.dataField.startsWith(prefix) && it.checked);
}

export function filterDevices(filterItems, devices) {
    const regionFilters = getCheckedFiltersByPrefix(filterItems, KEY_REGION_PREFIX);
    const hwVariantFilters = getCheckedFiltersByPrefix(filterItems, KEY_HWVARIANT_PREFIX);
    const actVehicleFilters = getCheckedFiltersByPrefix(filterItems, KEY_VEHICLE_STATUS_PREFIX);
    const brandFilters = getCheckedFiltersByPrefix(filterItems, KEY_BRAND_PREFIX);
    const vehVariantFilters = getCheckedFiltersByPrefix(filterItems, KEY_VVARIANT_PREFIX);

    // everything is selected, use all devices
    if (regionFilters.length +
        hwVariantFilters.length +
        actVehicleFilters.length +
        brandFilters.length +
        vehVariantFilters.length === filterItems.length) {
        return devices;
    }

    // if one of the available filters is not selected, use nothing
    if (regionFilters.length < 1 ||
        hwVariantFilters.length < 1 ||
        actVehicleFilters.length < 1 ||
        brandFilters.length < 1 ||
        vehVariantFilters.length < 1) {
        return [];
    }

    // filtering is done via these steps:
    // 1. filter devices by activation
    // then use this result as input for filtering by:
    // 2. region aka gateway
    // 3. hwVariants
    // 4. brands
    // 5. vehVariants
    const devicesByActivation = applyFilter(devices, actVehicleFilters, KEY_VEHICLE_STATUS_PREFIX);
    const devicesByRegion = applyFilter(devicesByActivation, regionFilters, KEY_REGION_PREFIX);
    const devicesByHwVariant = applyFilter(devicesByRegion, hwVariantFilters, KEY_HWVARIANT_PREFIX);
    const devicesByBrands = applyFilter(devicesByHwVariant, brandFilters, KEY_BRAND_PREFIX);
    return applyFilter(devicesByBrands, vehVariantFilters, KEY_VVARIANT_PREFIX);
}
