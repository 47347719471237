import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';

import { addLocaleData } from 'react-intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';

const DEFAULT_LOCALE = 'en-GB';

// React Intl relies on locale data to support its plural and relative-time
// formatting features, even if your application doesn't support all of these they
// should still be adding all officially supported locales

addLocaleData([
    ...de,
    ...en,
]);

const supportedLocaleMap = {
    de: 'de-DE',
    'de-DE': 'de-DE',
    en: 'en-GB',
    'en-GB': 'en-GB',
};

const extractLanguage = flow(
    defaultTo(DEFAULT_LOCALE),
    split('-'),
    head
);

const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

export {
    DEFAULT_LANG,
    DEFAULT_LOCALE,
    extractLanguage,
    supportedLocaleMap,
};
