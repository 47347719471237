import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';
import { DefaultAccountMenu } from 'rio-accountmenu';
import { SessionExpiredDialog } from 'rio-session-expired-info';
import { ApplicationHeader, NotificationsContainer, ApplicationLayout } from 'rio-uikit';

// Import language configuration
import { DEFAULT_LOCALE } from './lang/lang';
import { hideSessionExpiredDialog } from './app/actions';
import { getSessionExpiredAcknowledged } from './app/selectors';
import { getLanguageData, getLocale } from './lang/selectors';
import { isUserSessionExpired } from './login/selectors';
import { getAccessToken, getIdToken } from './tokenHandling/selectors';

// Import some service containers
import HeatMap from './app/HeatMap';

import {env} from '../env';

const {
    USER_SETTINGS_SERVICE,
} = env.runtimeConfig.backend;

const { DEVICE_STATE } = env.runtimeConfig.frontend;

// Define the navigation items of the current application
const appMenuItems = [
    {
        key: 'devicestate',
        route: (
            <a href={DEVICE_STATE}>
                <FormattedMessage id='service.title.deviceState'/>
            </a>
        ),
    },
];

export const AppContainer = (props) => {
    const {
        accessToken,
        idToken,
        hideSessionDialog,
        homeRoute,
        languageData,
        showSessionExpired,
        userLocale: locale,
    } = props;
    const appTitle = <FormattedMessage id={'app.moduleName'}/>;
    const accountMenu = (
        <DefaultAccountMenu
            accessToken={accessToken}
            idToken={idToken}
            userSettingsEndpoint={USER_SETTINGS_SERVICE}
        />
    );

    const applicationBody = (
        <div className='ApplicationLayoutBody'>
            <div className='width-100pct height-100vh'>
                <NotificationsContainer />
                <SessionExpiredDialog
                    locale={locale}
                    onClose={hideSessionDialog}
                    show={showSessionExpired}
                />
                <Switch>
                    <Route path='/heatmap' component={HeatMap} />
                    <Redirect to='/heatmap'/>
                </Switch>
            </div>
        </div>
    );

    const homeLink = <a href={homeRoute} />;

    return (
        <IntlProvider locale={locale} messages={languageData} defaultLocale={DEFAULT_LOCALE}>
            <ApplicationLayout className={'HeatMap'}>
                <ApplicationLayout.Header>
                    <ApplicationHeader
                        label={appTitle}
                        homeRoute={homeLink}
                        appMenuItems={appMenuItems}
                        actionBarItems={[accountMenu]}
                    />
                </ApplicationLayout.Header>
                {applicationBody}
            </ApplicationLayout>
        </IntlProvider>
    );
};

export const mapDispatchToProps = (dispatch) => ({
    hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
});

export const mapStateToProps = (state) => {
    return {
        accessToken: getAccessToken(state),
        idToken: getIdToken(state),
        homeRoute: env.runtimeConfig.homeRoute,
        languageData: getLanguageData(state),
        showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
        userLocale: getLocale(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
