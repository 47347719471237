import { configureEnv } from '../../env';

export const configureRedirectToLogout = (window, processEnv) => {
    const env = configureEnv(window, processEnv);
    const { logoutUri } = env.runtimeConfig;

    return () => {
        window.location.href = logoutUri;
    };
};

export const redirectToLogout = configureRedirectToLogout(window, process.env);
