import {authorizeFetch} from '../../fetch';
import {env} from '../../env';
import {accessToken} from '../tokenHandling/accessToken';
import {Notification} from 'rio-uikit';
import queryString from 'query-string';
import {
    devicesFetchSuccessful,
    handleIsSearching,
    handleSaveMapFilterSettings,
} from './actions';
import {store} from '../../setup/store';
import {mapFilterSettings, isBlank} from '../mappers/Mapper';

export const DEVICES_ENDPOINT_URL = '/devices';

const {
    DEVICE_INFO_SERVICE,
} = env.runtimeConfig.backend;

export const fetchAndHandleError = (url, fetch = window.fetch, Notify = Notification) => {
    const get = authorizeFetch(accessToken, fetch);
    return get(url)
        .then(response => {
            if (!response || !response.ok || response.status === 401) {
                throw new Error(`Fetching ${url} failed!`);
            }
            return response.json();
        })
        .catch(error => {
            Notify.error(error.message);
        });
};

export const fetchDevices = (reduxStore = store, fetch = window.fetch) => {
    reduxStore.dispatch(handleIsSearching(true));
    return request(fetch).then(devices => {
        if (devices && devices.length) {
            reduxStore.dispatch(devicesFetchSuccessful(devices));
            reduxStore.dispatch(handleSaveMapFilterSettings(mapFilterSettings(devices)));
        }
        reduxStore.dispatch(handleIsSearching(false));
    });
};

async function request(fetch = window.fetch) {
    let nextId = '';
    let limit;
    let devices = [];
    do {
        const response = await fetchAndHandleError(getDevicesUrl(nextId, limit), fetch);
        if (response && response.items) {
            devices = devices.concat(response.items);
            nextId = response.paging ? response.paging.cursor_next : undefined;
            limit = response.paging ? response.paging.limit : undefined;
        } else {
            return [];
        }
    } while (nextId);

    return devices;
}

function getDevicesUrl(nextId, limit) {
    let url = `${DEVICE_INFO_SERVICE}${DEVICES_ENDPOINT_URL}`;
    const queryParams = queryString.stringify({
        ...(limit && {
            limit: limit,
        }),
        ...(nextId && {
            cursor: nextId,
        }),
    });

    if (!isBlank(queryParams)) {
        url += `?${queryParams}`;
    }

    return url;
}
