// This is the runtime configuration being used for tests

export const testConfig = {
    backend: {
        USERADMIN_SERVICE: `${window.location.origin}/usersettings`,
        USER_SETTINGS_SERVICE: `${window.location.origin}/usersettings`,
        DEVICE_INFO_SERVICE: `${window.location.origin}/device-info`,
    },
    frontend: {
        DEVICE_STATE: `${window.location.origin}/device-state`,
    },
    homeRoute: `${window.location.origin}`,
    id: 'env.test',
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: false,
        mockLocale: 'de-DE',
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
};

