import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';

import {getFilteredPositions} from './selectors';
import {handleSetShowFilterSettings} from './actions';

import {clusterLayer} from './layers/ClusterLayer';
import {heatLayer} from './layers/HeatLayer';
import {markerLayer} from './layers/MarkerLayer';

import L from 'leaflet';
import {FormattedMessage} from 'react-intl';

const CLUSTER_VIEW = 'Cluster View';
const HEAT_VIEW = 'Heat View';
const MARKER_VIEW = 'Marker View';

const baseTileLayer = () => {
    return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    });
};

export class MapComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.map = {};
        this.controlLayer = undefined;
        this.heatmapLayer = undefined;
        this.markerLayer = undefined;
        this.clusterLayer = undefined;
        this.isClusterLayerSelected = true; // cluster layer is selected by default
        this.isMarkerLayerSelected = false;
        this.isHeatLayerSelected = false;
        this.zoomLevel = 3; // initial zoom level of the map
        this.center = [51.5, -0.09]; // initial center of the map
    }

    createMap() {
        const node = ReactDOM.findDOMNode(this);
        this.map = L.map(node, {preferCanvas: true}).setView(this.center, this.zoomLevel);

        baseTileLayer().addTo(this.map);

        this.map.on('baselayerchange', (e) => {
            this.isClusterLayerSelected = e.name === CLUSTER_VIEW;
            this.isHeatLayerSelected = e.name === HEAT_VIEW;
            this.isMarkerLayerSelected = e.name === MARKER_VIEW;
        });

        this.map.on('zoom', (e) => {
            if (e.sourceTarget) {
                this.zoomLevel = e.sourceTarget.getZoom();
            }

            this.setMarkerRadiusOnZoom();
        });

        this.renderControl();
        this.renderLayers();
    }

    setMarkerRadiusOnZoom() {
        this.markerLayer.getLayers().forEach(marker => {
            marker.setRadius(this.zoomLevel < 8 ? 1 : 4);
        });
    }

    resetMap() {
        // remember zoom level and center for re-rendering
        this.zoomLevel = this.map.getZoom();
        this.center = this.map.getCenter();

        this.heatmapLayer.remove();
        this.controlLayer.removeLayer(this.heatmapLayer);

        this.markerLayer.remove();
        this.controlLayer.removeLayer(this.markerLayer);

        this.clusterLayer.remove();
        this.controlLayer.removeLayer(this.clusterLayer);
    }

    componentDidUpdate() {
        const {positions} = this.props;

        this.resetMap();

        this.renderLayers(positions);
    }

    componentDidMount() {
        this.createMap();
    }

    createBaseLayer(positions) {
        this.markerLayer = markerLayer(this.map, positions, this.zoomLevel, this.isMarkerLayerSelected);
        this.heatmapLayer = heatLayer(this.map, positions, this.isHeatLayerSelected);
        this.clusterLayer = clusterLayer(this.map, positions, this.isClusterLayerSelected);
    }

    renderControl() {
        this.controlLayer = L.control.layers(null, null, {
            collapsed: false,
            position: 'bottomright',
        });

        this.controlLayer.addTo(this.map);
    }

    renderLayers(positions = []) {
        this.createBaseLayer(positions);

        this.controlLayer.addBaseLayer(this.clusterLayer, CLUSTER_VIEW);
        this.controlLayer.addBaseLayer(this.markerLayer, MARKER_VIEW);
        this.controlLayer.addBaseLayer(this.heatmapLayer, HEAT_VIEW);
    }

    renderFilterButton(setShowFilterSettings) {
        return (
            <div className='leaflet-bottom leaflet-left leaflet-control margin-bottom-10 margin-left-10'>
                <button
                    id='openSettingsButton'
                    className='btn btn-default'
                    type='button'
                    onClick={() => setShowFilterSettings(true)}
                    style={{pointerEvents: 'auto'}}>
                    <span className='rioglyph rioglyph-cog margin-right-0'/>
                </button>
            </div>
        );
    }

    renderDevicesCount(count) {
        return (
            <div className='leaflet-top leaflet-right leaflet-control margin-top-10 margin-right-10'>
                <div className='panel panel-default'>
                    <div className='panel-body'>
                        <FormattedMessage id={'service.heatmap.filter.vehicles.count'} values={{count: count}}/>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {positions, setShowFilterSettings} = this.props;

        return (
            <div id='map'>
                {this.renderFilterButton(setShowFilterSettings)}
                {this.renderDevicesCount(positions.length)}
            </div>
        );
    }

}

export const mapDispatchToProps = (dispatch) => {
    return {
        setShowFilterSettings: (value) => dispatch(handleSetShowFilterSettings(value)),
    };
};

export const mapStateToProps = (state) => {
    return {
        positions: getFilteredPositions(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);
