import L from 'leaflet';

function fillCircleMarkerList(positions, zoomLevel) {
    const markerList = [];
    const canvasRenderer = L.canvas({ padding: 0.5 });

    positions.forEach(pos => {
        const marker = L.circleMarker([pos.lat, pos.lng], {
            color: 'green',
            radius: zoomLevel < 8 ? 1 : 4,
            fillOpacity: 1,
            interactive: false,
            renderer: canvasRenderer,
        });
        markerList.push(marker);
    });

    return markerList;
}

export function markerLayer(map, positions, zoomLevel, isSelected) {
    const markerLayerGroup = L.layerGroup();

    if (isSelected) {
        markerLayerGroup.addTo(map);
    }

    const markerList = fillCircleMarkerList(positions, zoomLevel);
    markerList.forEach(m => markerLayerGroup.addLayer(m));

    return markerLayerGroup;
}
