import cloneDeep from 'lodash/fp/cloneDeep';

import { env } from '../env';

const initial = cloneDeep(env.runtimeConfig);

const configReducer = (state = initial, action = {}) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default configReducer;
