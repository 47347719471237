import get from 'lodash/fp/get';

export const getSessionExpiredAcknowledged = get('app.sessionExpiredAcknowledged');

export const getFilteredPositions = get('app.filteredPositions');

export const getIsSearching = get('app.isSearching');

export const getFilterItems = get('app.filterItems');

export const getShowSettings = get('app.showSettings');
