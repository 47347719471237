import getOr from 'lodash/fp/getOr';

import { configureReporting } from '../../setup/errorReporting';

import { changeLocale, languageDataFetched } from './actions';
import { getSupportedLocale as defaultGetSupportedLocale } from './selectors';

const { captureException } = configureReporting(window, process.env);

const MODULE_NAME = `web-vehicle-heatmap`;

const sendError = (exception) => {
    captureException(exception, {
        tags: {
            module: MODULE_NAME,
        },
    });
};

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport =
    (imported) => getOr(imported, 'default', imported);

export const getLanguageData = (locale) => (
    import(/* webpackChunkName: "[request]" */ `./translations/${locale}.json`)
        .then(normalizeDynamicImport)
        .catch(error => {
            sendError(error);
            return error;
        })
);

export const configureFetchLanguageData = (store,
    fetchLanguageData = getLanguageData, getSupportedLocale = defaultGetSupportedLocale) => (locale) => {
    if (!locale) {
        // eslint-disable-next-line no-console
        console.warn(`No "locale" supplied when fetching language data!`);
        return Promise.reject();
    }

    store.dispatch(changeLocale(locale));
    const supportedLocale = getSupportedLocale(store.getState());
    return fetchLanguageData(supportedLocale).then(languageData => {
        store.dispatch(languageDataFetched(supportedLocale, languageData));
    }).catch(error => {
        // eslint-disable-next-line no-console
        sendError(error);
        return error;
    });
};

