import React, {Component} from 'react';
import {connect} from 'react-redux';

import MapComponent from './MapComponent';
import {SpinnerInfoBox} from 'rio-uikit';

import {fetchDevices} from './services';
import {getIsSearching} from './selectors';

import MapFilterSettings from './MapFilterSettings';

export class HeatMap extends Component {

    componentDidMount() {
        fetchDevices();
    }

    renderSettingsDialog() {
        return (
            <div>
                <MapFilterSettings/>
            </div>
        );
    }

    render() {
        const {
            searching,
        } = this.props;

        return (
            <div className='heatmap height-100pct position-relative' ref={elem => (this.domRef = elem)}>
                {this.renderSettingsDialog()}
                <MapComponent/>
                { searching &&
                <div
                    // eslint-disable-next-line max-len
                    className={'display-flex bg-info justify-content-center align-items-center height-100pct width-100pct'}
                    style={{opacity: 0.5, position: 'absolute', top: '0', left: '0', zIndex: '100000'}}>
                    <SpinnerInfoBox text={'Loading'} isInverse />
                </div>
                }
            </div>
        );
    }
}

export const mapDispatchToProps = () => ({});

export const mapStateToProps = (state) => {
    return {
        searching: getIsSearching(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeatMap);
