/* eslint-disable max-len */
// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

export const productionConfig = {
    backend: {
        USERADMIN_SERVICE: 'https://api.iam.ccp-prod.net/api/useradmin',
        USER_SETTINGS_SERVICE: 'https://api.iam.ccp-prod.net/api/useradmin',
        DEVICE_INFO_SERVICE: `https://api.vcp-connector.rio.cloud/device-info`,
    },
    frontend: {
        DEVICE_STATE: 'https://device-state.vcp-connector.rio.cloud/index.html',
    },
    homeRoute: 'https://admin-eu.rio.cloud',
    id: 'env.production',
    login: {
        clientId: '395d6c94-7085-4068-9cfa-021cfe60b30d',
        authority: `https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2`,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: `https://heatmap.vcp-connector.rio.cloud/redirect.html`,
        silentRedirectUri: `https://heatmap.vcp-connector.rio.cloud/redirect.html`,
    },
    logoutUri: `https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2/oauth2/v2.0/logout?redirect_uri=https://heatmap.vcp-connector.rio.cloud/redirect.html`,
};

