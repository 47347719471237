export const mapUserProfile = (profile = {}) => ({
    azp: profile.azp,
    email: profile.email,
    familyName: profile.family_name,
    givenName: profile.given_name,
    locale: profile.locale,
    name: profile.name,
    sub: profile.sub,
    ...profile,
});

